import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import LandscapeImage from "../elements/landscape-image";
import { formatVotes } from "../helpers/photo-comp";

const Photo = ({ title, image, alt = "", photographer, votes, link }) =>
  link &&
  image && (
    <Link className="c-photo-tile" to={link}>
      <figure className="c-photo-tile-figure">
        {image && <LandscapeImage image={image} alt={alt} />}
        <figcaption>
          <span className="c-photo-tile-votes subtitle">
            {formatVotes(votes)}
          </span>
          {title && <h2 className="c-photo-tile-title h5">{title}</h2>}
          {photographer && (
            <h3 className="c-photo-tile-photographer body-small">
              {photographer}
            </h3>
          )}
        </figcaption>
      </figure>
    </Link>
  );

const PhotoTile = React.memo(Photo);

export const PhotoPropTypes = {
  title: PropTypes.string,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  alt: PropTypes.string,
  photographer: PropTypes.string,
  votes: PropTypes.number,
  link: PropTypes.string,
};

Photo.propTypes = PhotoPropTypes;

export default PhotoTile;
