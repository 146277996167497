import React from "react";
import { graphql, Link } from "gatsby";
import PropTypes from "prop-types";
import ContentLoader from "react-content-loader";
import BlockContent from "@sanity/block-content-to-react";
import Layout from "../layouts";
import SEO from "../components/seo";
import Header from "../modules/header";
import PhotoTile from "../components/photo-tile";
import { getVoteTotals } from "../helpers/photo-comp";
import serializers from "../helpers/text-serializer";

export const query = graphql`
  query PhotoCompPageTemplateQuery($id: String!) {
    page: sanityPagePhotoComp(id: { eq: $id }) {
      title
      excerpt
      formTeaser
      formButton
      path
      _rawBody(resolveReferences: { maxDepth: 8 })
      breadcrumbs {
        title
        slug
      }
      seo {
        title
        description
        keywords
        image {
          asset {
            _id
            altText
            metadata {
              lqip
              dimensions {
                width
                height
                aspectRatio
              }
            }
          }
          hotspot {
            height
            width
            x
            y
          }
        }
      }
    }
    entries: allSanityPhotoCompEntry(filter: { publish: { eq: true } }) {
      nodes {
        _id
        caption
        firstName
        lastName
        path
        image {
          asset {
            _id
            altText
            metadata {
              lqip
              dimensions {
                width
                height
                aspectRatio
              }
            }
          }
        }
      }
    }
  }
`;

const CalendarComp = ({ data }) => {
  const page = data.page;

  const [entries, setEntries] = React.useState([]);

  React.useEffect(() => {
    getEntries();
  }, []);

  const getEntries = async () => {
    const totals = await getVoteTotals();
    const entriesWithVotes = data.entries.nodes
      .map(entry => {
        const votes = entry._id in totals ? totals[entry._id] : 0;
        return {
          ...entry,
          votes,
        };
      })
      .sort((a, b) => {
        return b.votes - a.votes;
      });
    setEntries(entriesWithVotes);
  };

  const Loader = props => (
    <ContentLoader
      speed={2}
      width={264}
      height={264}
      viewBox="0 0 264 264"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      {...props}
    >
      <rect x="0" y="0" rx="0" ry="0" width="264" height="148" />
      <rect x="0" y="155" rx="0" ry="0" width="86" height="20" />
      <rect x="0" y="200" rx="0" ry="0" width="264" height="12" />
      <rect x="0" y="225" rx="0" ry="0" width="264" height="12" />
      <rect x="0" y="250" rx="0" ry="0" width="264" height="12" />
    </ContentLoader>
  );

  return (
    <Layout className="p-calendar-comp">
      <SEO
        title={page.title}
        excerpt={page.excerpt}
        image={page.image}
        seo={page.seo}
        taxonomy={page.taxonomy}
      />
      <Header
        title={page.title}
        breadcrumbs={page.breadcrumbs}
        backgroundColour="beige"
      />
      <div className="container">
        {page._rawBody?.[0] && (
          <BlockContent
            className="block-body"
            blocks={page._rawBody}
            serializers={{
              serializers,
              types: {
                block: serializers.types.block,
                undefined: serializers.undefined,
                blockVideo: serializers.blockVideo,
                blockFigure: serializers.blockFigure,
                pdfButton: serializers.pdfButton,
              },
              marks: {
                link: serializers.marks.link,
              },
            }}
            renderContainerOnSingleChild={true}
          />
        )}
        <section className="p-calendar-comp-teaser">
          <p>{page.formTeaser}</p>
          <div>
            <Link to={`${page.path}/enter`} className="e-button -primary">
              {page.formButton}
            </Link>
          </div>
        </section>
        <section className="p-calendar-comp-entries">
          {entries.length
            ? entries.map(entry => (
                <PhotoTile
                  image={entry.image}
                  title={entry.caption}
                  photographer={`${entry.firstName} ${entry.lastName}`}
                  votes={entry?.votes}
                  link={entry.path}
                  alt={entry.image.asset.altText}
                  key={entry._id}
                />
              ))
            : data.entries.nodes.map((entry, i) => <Loader key={i} />)}
        </section>
      </div>
    </Layout>
  );
};

CalendarComp.propTypes = {
  data: PropTypes.object,
};

export default CalendarComp;
