import React from "react";
import PropTypes from "prop-types";
import ImageHelper from "../helpers/image";

const LandscapeImage = ({ image, alt = "" }) =>
  image && (
    <div className="e-landscape-image-container e-image-container">
      <ImageHelper image={image} alt={alt} />
      {/* <img className="e-image" src={image} alt={alt} /> */}
    </div>
  );
export default LandscapeImage;

export const LandscapeImagePropTypes = {
  image: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      _id: PropTypes.string,
      altText: PropTypes.string,
    }),
  ]),
  alt: PropTypes.string,
};

LandscapeImage.propTypes = LandscapeImagePropTypes;
