import React from "react";
import { getGatsbyImageData } from "gatsby-source-sanity";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import opengraph from "../../static/img/opengraph.png";
import sanityConfig from "../../sanity.json";
import PropTypes from "prop-types";

function SEO({ title, excerpt, image, meta = {}, seo, taxonomy }) {
  const { site, allSanityGlobalSeo } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            siteUrl
          }
        }
        allSanityGlobalSeo {
          nodes {
            title
            description
            image {
              asset {
                _id
                altText
                metadata {
                  lqip
                  dimensions {
                    width
                    height
                    aspectRatio
                  }
                }
              }
              hotspot {
                height
                width
                x
                y
              }
            }
          }
        }
      }
    `
  );
  const fallback = allSanityGlobalSeo.nodes[0];

  const description =
    seo?.description ||
    excerpt ||
    fallback?.description ||
    site.siteMetadata.description;
  const ogDescription =
    seo?.description || fallback?.description || description;
  const keywords = getKeywords({ seo, taxonomy });

  let ogImage = `${site.siteMetadata.siteUrl}${opengraph}`;
  let img = seo?.image || fallback?.image || image;
  if (img) {
    const imageData = getGatsbyImageData(
      img.asset,
      {
        width: 1200,
        height: 630,
        layout: "fixed",
      },
      sanityConfig
    );

    ogImage = imageData.images.fallback.src;
  }

  return (
    <Helmet
      htmlAttributes={{
        lang: "en",
      }}
      title={seo?.title || title}
      titleTemplate={
        (title === fallback?.title) | (title === site.siteMetadata.title)
          ? `%s`
          : `%s | ${fallback?.title || site.siteMetadata.title}`
      }
      meta={[
        {
          name: `description`,
          content: description,
        },
        {
          name: `keywords`,
          content: keywords.join(", "),
        },
        {
          property: `og:title`,
          content: seo?.title || title,
        },
        {
          property: `og:description`,
          content: ogDescription,
        },
        {
          property: `og:image`,
          content: ogImage,
        },
        {
          property: `og:image:width`,
          content: 1200,
        },
        {
          property: `og:image:height`,
          content: 630,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:site_name`,
          content: fallback?.title || site.siteMetadata.title,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
      ].concat(meta)}
    />
  );
}

const getKeywords = ({ taxonomy, seo }) => {
  let keywords = [];
  if (seo?.keywords.length) {
    keywords = [...keywords, ...seo.keywords];
  }
  if (taxonomy?.topic) {
    taxonomy?.topic.title && keywords.push(taxonomy.topic.title);
    taxonomy?.topic.parentTitle && keywords.push(taxonomy.topic.parentTitle);
  }
  if (taxonomy?.tags.length) {
    keywords = [...keywords, ...taxonomy.tags.map(tag => tag.title)];
  }
  keywords = keywords.filter(word => word !== null && word !== undefined);

  return keywords;
};

export const SEOPropTypes = {
  title: PropTypes.string,
  excerpt: PropTypes.string,
  image: PropTypes.object,
  meta: PropTypes.array,
  taxonomy: PropTypes.object,
  seo: PropTypes.shape({
    keywords: PropTypes.arrayOf(PropTypes.string),
    title: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.object,
  }),
};

SEO.propTypes = SEOPropTypes;

export default SEO;
